import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LivroAutorTable from './LivroAutorTable';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import LivroAutorFindDialog from './LivroAutorFindDialog';
import { errorMessage } from '../../../util/ConfirmAlertUtil';
import { page } from './api-livro-autor';
import { useHistory } from 'react-router';
import Loading from '../../UI/Loading';

const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px;',
        margin: '20px'
    },
    title: {
        marginBottom: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const LivroAutorContainer = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSearch, setOpenSearch] = useState(false);
    const [livrosAutor, setLivrosAutor] = useState({
        content: [],
        size: 0,
        totalElements: 0,
        number: 0
    });
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);



    useEffect(() => {
        fetchData(0);
    }, []);

    const fetchData = (newPage) => {
        setIsLoading(true);
        page(newPage).then((data) => {
            if (data) {
                if (data.error) {
                    errorMessage(data.message);
                } else {
                    setLivrosAutor(data)
                }
            }
            setIsLoading(false);
        })
    }

    const handleChangePage = (event, newPage) => {
        fetchData(newPage);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleProcurarLivro = () => {
        handleMenuClose();
        setOpenSearch(true);
    }

    const handleSelectLivroAutor = (la) => {
        setLivrosAutor((prevState) => ({
            ...prevState,
            content: [la]
        }))
    }

    const handleDescricaoAutor = () => {
        history.push("/descricao-autor");
    }


    return (
        <Container className={classes.paper} component={Paper}>
            <LivroAutorFindDialog
                openDialog={openSearch}
                setOpenDialog={setOpenSearch}
                setLivroAutor={handleSelectLivroAutor}
            />
            <Grid container justifyContent="space-between">
                <Grid item xs={3}>
                    <Typography className={classes.title} variant="h6">
                        Livros Autor
                    </Typography>
                </Grid>
                <Grid style={{ flexBasis: '5%' }} item xs={3}>
                    <MenuIcon
                        fontSize='small'
                        className={classes.cursorLink}
                        onClick={(event) => handleMenuClick(event)}
                        color="secondary"
                    />
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleProcurarLivro()}>
                            Procurar Livro Autor
                        </MenuItem>
                        <MenuItem onClick={() => handleDescricaoAutor()}>
                            Descrição Autor
                        </MenuItem>
                    </Menu>

                </Grid>

            </Grid>

            <Grid >
                {isLoading ? <Loading description='Carregando Livro Autor...' /> :
                    <LivroAutorTable
                        livrosAutor={livrosAutor}
                        handleChangePage={handleChangePage}
                    />
                }


            </Grid>

        </Container>
    );
}

export default LivroAutorContainer;