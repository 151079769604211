import { Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import React from 'react';
import Button from '../../UI/Button';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
    paper: {
        padding: '20px',
    },
    table: {
        paddingTop: '20px',
        margin: '20px'
    },
    cursorLink: {
        cursor: 'pointer'
    }
});

const ComunicadoMenu = ({ navigation }) => {
    const classes = useStyles();

    return (

        <Container className={classes.paper} component={Paper}>
            <Grid
                container
                spacing={3}
            >
                <Grid item xs={12}>
                    <Typography variant="h6">
                        Comunicado Menu
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Grid container direction='row' justifyContent='space-around'>
                        <Grid item>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/comunicado'
                                }}
                            >Enviado</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                component={Link}
                                to={{
                                    pathname: '/comunicado-aluno'
                                }}
                            >Recebido</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Container>
    )
}

export default ComunicadoMenu;